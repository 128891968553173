.profilepage-breadcrumb {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #3a3a3a;

  margin-bottom: 30px;
}
.titleBusinessEvent{
  

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;
/* identical to box height */



color: #000000;
}.dateContainer{
  display: flex;
  justify-content: space-between;
 gap: 10px;
  
 

}.dateContainerSub{
  width: 100%;
}

.hospitalEnquiry{
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;
/* identical to box height */

letter-spacing: 0.1px;

color: #000000;


}.hospitalEnquiryBody{
  display: flex;
  justify-content: space-between;
}.response{


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

letter-spacing: 0.1px;

color: #3CBA84;
}.selectDate{
 

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

letter-spacing: 0.1px;

color: #3A3A3A;
}.selectedDateContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}.calenderImg{
  width: 18px;
  height: 18px;
  margin-right: 10px;
}.listContainer{
  box-sizing: border-box;
background: #FFFFFF;
border: 0.5px solid rgba(157, 159, 162, 0.42);
border-radius: 5px;
}

.listBorder{


background: rgba(60, 186, 132, 0.05);
border-radius: 5px 5px 0px 0px;
display: flex;
justify-content: space-around;

}.listTitle{

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 30px;
width: 125px;
/* identical to box height */

letter-spacing: 0.1px;

color: #005D8E;
}.rowList{
  display: flex;
  justify-content: space-between;
 
  background: rgba(157, 159, 162, 0.42);
  margin-bottom:0.5px;
 
}.box{
  width: 235px;
  display: grid;
  justify-content: center;
 padding: 10px;
  margin-right:0.5px;
 background-color: #FFFFFF;

 
  
}.boxMain{
background: rgba(157, 159, 162, 0.42);
  
}.addComment{
  box-sizing: border-box;
  margin-top: 2px;
height: 100px;



background: #FFFFFF;
border: 0.5px solid #ADADAD;
border-radius: 5px;
overflow: hidden;
word-break: break-all;

}.inputComment{
  overflow: hidden;
  outline:none;/* identical to box height */

letter-spacing: 0.1px;
word-break: break-all;
border:none;
}.listNewContainer{
  margin-bottom: 20%;
}.serviceName{

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 30px;
/* identical to box height */

letter-spacing: 0.1px;

color: #3A3A3A;
}.patientId{


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

letter-spacing: 0.1px;

color: rgba(58, 58, 58, 0.76);
}.transactionDate{
  

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

text-align: center;
letter-spacing: 0.1px;

color: #3A3A3A;

}.typeComment{


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;


/* identical to box height */

letter-spacing: 0.1px;
margin-top: 5px;
margin-left: 5px;

color: #9D9FA2;
outline:none;/* identical to box height */

letter-spacing: 0.1px;
border:none;

}.refundMoney{
 

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

text-align: center;
letter-spacing: 0.1px;

text-decoration-line: underline;

color: #3CBA84;
}.divRefund{
  display: flex;

  justify-content: center;

  height: 100%;

}

