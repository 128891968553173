.profilepage-breadcrumb {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #3a3a3a;


}

.maincareplan {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
}

.searchbar {
  background-color: white;
  width: 200px;
  border-radius: 10px;
  height: 2.7rem;
  padding: 0 8px;
  box-shadow: 0px 0px 8 px #ddd;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
}

/* input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 15px;
  width: 100%;
  margin-left: 5px;
} */

input:focus {
  outline: none;
}





.flagView {
  flex-direction: row;
  align-items: center;
  display: flex;

}

.titleBusinessEvent {


  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;

  /* identical to box height */

  letter-spacing: 0.1px;

  color: #000000;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
}

.dateContainerAlert {
  display: flex;
  justify-content: space-between;
}

.hospitalEnquiry {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #000000;

}

.hospitalEnquiryBody {
  display: flex;
  justify-content: space-between;
}

.response {


  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #3CBA84;
}

.selectDate {


  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #3A3A3A;
}

.selectedDateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calenderImg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.listContainer {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 0.5px solid rgba(157, 159, 162, 0.42);
  border-radius: 5px;
}

.listBorder {


  background: rgba(60, 186, 132, 0.05);
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-around;

}

.listTitle {

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;

  line-height: 30px;
  word-break: keep-all;
  word-wrap: break-word;
  width: 140px;
  text-align: center;


  /* identical to box height */

  letter-spacing: 0.1px;

  color: #005D8E;
}

.box_title .listTitle {
  font-size: 14px;
}

.rowListEvent {
  display: flex;

  background: rgba(157, 159, 162, 0.42);
  margin-bottom: 0.5px;

}

.boxSub {
  padding: 10px;


  flex: 1;
  text-overflow: ellipsis;

  background-color: #ffffff;
  margin-right: 0.5pt;




}

.rowList {
  display: flex;

  background: rgba(157, 159, 162, 0.42);
  margin-bottom: 0.5pt;



}

.infinitiveScroll {

  width: 100%;
  height: 100%;


}

.boxMain {
  background: rgba(157, 159, 162, 0.42);

}

.addComment {
  box-sizing: border-box;
  margin-top: 2px;



  background: #FFFFFF;
  border: 0.5px solid #ADADAD;
  border-radius: 5px;

}

.inputComment {
  outline: none;
  /* identical to box height */

  letter-spacing: 0.1px;
  word-break: break-all;
  border: none;
}

.listNewContainer {
  margin-bottom: 20%;
  overflow: auto;
  margin-top: 28px;

}

.listNewContainerInfinitive {
  margin-bottom: 20%;
  height: 100%;

}

.serviceName {

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  word-break: keep-all;
  word-wrap: break-word;

  /* identical to box height */
  text-align: center;

  letter-spacing: 0.1px;

  color: #3A3A3A;
}

.patientIdNew {


  font-family: 'Open Sans';
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  word-break: keep-all;
  word-wrap: break-word;
  align-self: center;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;



  letter-spacing: 0.1px;

  color: rgba(58, 58, 58, 0.76);
}

.patientComment {


  font-family: 'Open Sans';
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  word-break: keep-all;
  word-wrap: break-word;
  align-self: center;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;



  letter-spacing: 0.1px;

  color: rgba(7, 7, 239, 0.76);
}

.downLoadFile {


  font-family: 'Open Sans';

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  word-break: keep-all;
  word-wrap: break-word;
  align-self: center;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;



  letter-spacing: 0.1px;

  color: rgba(7, 7, 239, 0.76);
}

.patientId {


  font-family: 'Open Sans';
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  word-break: keep-all;
  word-wrap: break-word;
  align-self: center;
  text-align: center;
  text-overflow: ellipsis;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;



  letter-spacing: 0.1px;

  color: rgba(58, 58, 58, 0.76);
}

.transactionDate {


  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1px;

  color: #3A3A3A;

}

.typeComment {


  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.1px;
  margin-top: 5px;
  margin-left: 5px;

  color: #9D9FA2;
  outline: none;
  /* identical to box height */

  letter-spacing: 0.1px;
  border: none;

}

.refundMoney {


  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1px;
  text-decoration-line: underline;

  color: #3CBA84;
}

.divRefund {
  display: grid;
  justify-content: space-between;

}

.topping {

  padding: 5px;
  width: 100%;
  text-align: right;


}

.calenderFlag {
  margin-left: 8px;
  margin-top: 5px;
  align-self: center;

  width: 18px;
  height: 18px;
}

.border_bottom td {
  border-collapse: collapse;
  padding: 18px;
  align-items: center !important;
}

.border_bottom {
  border-top: 1px solid #CDCDCD;
  text-align: center;
  font-size: 14px;
  /* white-space: nowrap; */
}

.border_bottom_popup {
  white-space: nowrap;
}

.border_bottom1 {
  border-top: 1px solid #CDCDCD;
  text-align: center;

}

.tble {
  display: table-cell;
  background-color: white;
  border-collapse: collapse;
  padding: 10px;
  width: 100%;
  /* text-align: center; */
}

.tblepopup {
  display: table-cell;
  background-color: white;
  border-collapse: collapse;
  padding: 0px;
  width: 100%;
  /* text-align: center; */
}

.tblepopup1 td {
  border-collapse: collapse;
  padding: 10px 28px !important;
}

/* .p-dialog .p-dialog-header {
  padding: 21px 15px 0px 15px !important;
} */
/* .p-dialog .p-dialog-header {
  padding: 12px 15px 14px 15px !important;
} */

.tblepopup .tblepopuptr {
  background-color: rgba(60, 186, 132, 0.08);
}

.headerdiv {
  display: flex;
}

.usercode {
  font-size: 14px;
  font-weight: 400;
  color: rgba(38, 38, 38, 0.76);
}

.head_name {
  margin-bottom: 0px;
  margin-top: 0px;
}

.mainhead {
  margin-left: 20px;
}

.table_heading {
  border-top: 1px solid #CDCDCD;
  border-bottom: 1px solid #CDCDCD;
  padding: 9px 0px;
  font-size: 18px;
}

.dummyimg_icon {
  height: 45px;
}

.heading {
  /* border-top: 1px solid #ddd; */
  padding: "8px";
  color: #005D8E;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  padding-top: 15px;
  padding-bottom: 15px;

}

.usercode {
  margin-top: 7px !important;
  font-size: small;
}

.tr border_bottom {
  column-rule: 4px solid red;
  font-size: 14px;
}
/* table td + td { border-bottom:1px solid gray;} */

table td + td { border-bottom:1px solid #CDCDCD; }
/* table th + th { border-bottom:1px solid gray; } */
.popupbtn {
  background-color: #fff !important;
  border: none !important;
  padding-right: 16px;
  cursor: pointer;
}

.heading {
  padding-left: 5px;
  padding-right: 5px;
}

.heading1 {
  padding-left: 10px;
  padding-right: 10px;
  color: #005D8E;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  padding-top: 15px;
  padding-bottom: 15px;
}


.overlay {
  position: fixed;
  top: 315px;
  left: 291px;
  right: 0;
  bottom: 0;
  background-color: whitesmoke;
  z-index: 9999;
  width: 1030px;
  height: 584px;
}

.border_bottom2nd td {
  border-collapse: collapse;
  padding: 10px;
  align-items: center !important;
  border-top: 1px solid #CDCDCD;
}

/* table tr td,
table tr th {
  white-space: nowrap;
} */
table tr th {
  white-space: nowrap;
}


/* .p-dialog-header {
  padding: 20px 15px 4px 15px;
} */

.fa-pencil-square-o {
  color: #005D8E;
  margin-right: 10px;
  cursor: pointer;

}

.datepicker {

  width: 145px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #FFFFFF;
  /* border: 1px solid #E2E2E2;
  border-radius: 5px; */
  /* width: 166px;
  height: 39px; */
  cursor: pointer;
  display: flex;
}
.datepicker1{
  width: 190px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #FFFFFF;
  cursor: pointer;
  display: flex;
}

.form-control {
  color: #828282;
  font-size: 14px;
  border: none;
}

.commentcss {
  /* box-sizing: border-box; */

  width: 136px;
  height: 43px;
  padding: 12px;

  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  display: flex;
  gap: 10px;
}

.textalign {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #005D8E;
  margin-top: 0px;


}

.inpt {
  display: none;
}

.comntinpt {
  width: 135px;
  height: 43px;
  padding: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  margin: 7px;

  background: #FFFFFF;
  /* border: 1px solid #E2E2E2; */
  border-radius: 5px;
}
.comntinptservice {
  width: 180px;
  height: 43px;
  padding: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  margin: 7px;

  background: #FFFFFF;
  /* border: 1px solid #E2E2E2; */
  border-radius: 5px;
}
.dropdowntd{
 
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color:#495057;
  

  background: #FFFFFF;
  /* border: 1px solid #E2E2E2; */
  border-radius: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 260px;
}

.custom-dropdown .p-dropdown-trigger {
  display: none;
}


.fa-pencil-square-o1 {
  color: #005D8E;
  padding-left: 9px;
  cursor: pointer;
  padding-top: 6px;
}

/* .p-button.p-button-icon-only {
  padding: 8px 0;
} */
.main-table-container{
  /* height: 190px; */
  width: 100%;
  overflow-x: auto;
  /* overflow-y: auto; */
background-color: white;
}

.main-table-container1{
  /* height: 550px; */
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
background-color: white;
}

.main-tablegiver{
  background-color: white;
}

.table-container {


  /* height: 190px; */
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;


}

.my-table {



  width: 100%;

}
.my-tablepopup{
  width: 100%;
}

.my-tablepopup td{
  padding: 6px;
  font-size: 14px;

border-bottom: 1px solid #CDCDCD;

}
.my-tablepopup th{
  font-size: 16px;
  padding: 8px;
  background-color: rgba(60, 186, 132, 0.08);
  white-space: nowrap;
}

.my-table td {

  padding: 11px;
  font-size: 14px;

border-bottom: 1px solid #CDCDCD;


  /* white-space: nowrap; */
}

.my-table th {
  font-size: 16px;
  padding: 8px;
  background-color: rgba(60, 186, 132, 0.08);
  white-space: nowrap;
}

.buttn {
  background: #396DDD;
  border-radius: 5px;
  width: 126px;
  height: 39px;
  color: white;
  cursor: pointer;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  border: 1px solid #396DDD
}
.buttn:focus{
  background-color: white;
  color: #396DDD;
 
}

.maindivsearchbar {
  display: flex;
  gap: 20px;
  padding-top: 13px;
  margin-left: 26px;
}
/* Styles for disabled options */
.disabled-option {
  color: #cccccc;
}

/* Styles for non-disabled options */
.enabled-option {
  color: #302d2d;
}

.placeholder {
  font-size: 16px;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.options {
  cursor: pointer;
  width: 300px;
  min-height: 60px;
  max-height: 300px;
  border-radius: 15px;
  background-color: rgb(250, 250, 250);
  box-shadow: 2px 4px 8px #c5c5c5;
  transition: all 300ms;
}

.searchbarname {
  background-color: white;
  width: 200px;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0 8px;
  box-shadow: 0px 0px 8 px #ddd;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  cursor: pointer;
  color:#828282;

}
.fontsome{
  
    color: #005D8E;
    padding-left: 9px;
    cursor: pointer;
    padding-top: 6px;
   
    display: flex;
    justify-content: end;
    margin-top: -21px;
   
    margin-left: 16px;

}
.calandertime{
  background-color: white;
  width: 200px;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0 8px;
  box-shadow: 0px 0px 8 px #ddd;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border: 1px solid #E2E2E2; */
  border-radius: 5px;
  justify-content: center;
}
.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 50px;
  padding: 6px;
}
.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
   background-color:  #005D8E;
   color: white;
}
.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 300px;
  padding: 6px;
  border-bottom: 1px solid #CDCDCD; 
  border-top:none;
  border-left:none;
  border-right:none;
  border-radius: 0px;

}
.bookcss{
  background: rgb(38, 92, 137);
    font-size: small;
    padding: 6px;
    color: white;
    border-radius: 5px;
    border-block-color: b;
    border: 1px solid rgb(38, 92, 137)
}
/* .popupfield{
  height: 120px;
  border: 2px solid grey;
  border-radius: 5px;
  width: 313px;
    margin-left: 200px;
    margin-top: 22px;
} */

input.popupfield {
  display: block;
    height: 137px;
    width: 482px;
    padding: 10px;
   
    border: 2px solid grey;
    border-radius: 5px;
    box-sizing: border-box;
    margin-left: 24px;
}

input.popupfield::placeholder {
  opacity: 0.5;
}
/* .p-dialog .p-dialog-content {

  padding: 6px 7rem 3rem 6rem;
} */

/* .p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0px 2rem 3rem 1.5rem;
  text-align: center;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
} */
.footerpopcss{
  display: flex;
    justify-content: center;
    gap: 50px;
}
.p-datatable .p-datatable-thead > tr > th {

  font-size: 14px;
  padding: 8px 8px;
}
.p-datatable .p-datatable-tbody > tr {
 
  font-size: 14px;
}
.serviceprovider{

  width: 100%;
     font-family: 'Open Sans';
      font-style: normal;
      font-weight: 200;
      font-size: 14px;
      margin-left: 0px !important;
     
    margin-bottom: 12px;
      background: #FFFFFF;
      border-bottom: 1px solid #9f9595;
     

}
.primaryBtn123{
  box-sizing: border-box;
  width: 202px;
  height: 34px;
  background: #004171;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.cancelBtn123{
  width: 202px;
  height: 34px;
  border: 0.5px solid #004171;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.fontsome123{
  color: #005D8E;
  padding-left: 9px;
  cursor: pointer;
  padding-top: 6px;
 
  display: flex;
  justify-content: end;
 
  margin-top: -29px;
 
  margin-left: 80px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  /* width: 87px; */
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #343a40;
  /* border: 1px solid #dee2e6; */
  /* border-width: 1px 0 1px 0; */
  /* padding: 1rem 1rem; */
  font-weight: 700;
  border: none;
  padding: 0px;
}
.inputfield{  
  /* border: 1px solid #ccc;  */
    padding: 10px;
    /* border-radius: 5px; */
    border-bottom: 1px solid #ccc;
   width:260px;
        border-top: none;
        border-left: none;
        border-right: none;
   
}
.addbutton{margin-right: 15px;
  background: #42BA85;
  color: #fff;
  border: 1px solid #42BA85;
  padding: 0px 23px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px;
  height: 37px;
  margin-top: 1px;
  cursor: pointer;}
  .cancelbutton{
    background: #d62323;
    color: #fff;
    border: 1px solid #d62323;
    padding: 0px 23px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
    height: 37px;
    margin-top: 1px;
    cursor: pointer;
  }
  .calander{
    border: none;
  }
  .p-sidebar .p-sidebar-header {
    /* padding: 1.25rem; */
    margin-top: 10px;
    padding: 0px;
}
.editinfor{
  font-weight: 600;
font-size: 14px;
line-height: 19px;
margin-left: -3px;
margin-top: 8px;
background-color: white;
border: none;
letter-spacing: 0.1px;
text-decoration-line: underline;
cursor: pointer;
color: #005D8E;
}
.inputplaceholder{
  margin-top: 14px;
  width: 80%;
  padding: 7px;
  border-radius: 5px;
  border-color: #ccc;
}
.container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  min-width: 160px;
}

.dropdown-menu select {
  width: 100%;
  padding: 6px 12px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.42857143;
  border: none;
}

.dropdown-menu select:focus {
  outline: none;
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.5);
}

.fa-filter {
  margin-top: 10px;
  cursor: pointer;
}

.careplan {
 
  width: 167px !important;
  background-color: #f4faff !important;
  color: #000 !important;
  /* text-align: center !important; */

  padding: 8px 6px !important;

  border: 1px solid #cccccc !important;
}
.p-datatable > .p-datatable-wrapper{
  overflow: hidden !important;
}
.tooltip .careplan {
  margin-top: -100px !important;
}
.cancel-button{
   
    background-color: red;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    border-radius: 7px;
    margin-left: 50px;
  
}
.imagecss{
  display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-around;
}
.file-input-wrapper { position: relative; overflow: hidden; display: inline-block; } .custom-file-input { display: inline-block; padding: 8px 16px; background-color: #e0e0e0; color: #333; border: none; border-radius: 4px; cursor: pointer; } .actual-file-input { position: absolute; top: 0; left: 0; opacity: 0; height: 100%; width: 100%; cursor: pointer; }
.rowcolor{
  background-color: rgb(245, 209, 215);
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  padding: 1.5rem;
  border-top-right-radius: 6px; 
  border-top-left-radius: 6px;
  padding-bottom: 0px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 257px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
  width: 260px;
}
.icon-container {
  background-color: rgba(255, 192, 203, 0.7); /* Transparent pink color */
  width: 100px; /* Adjust the width and height as needed to fit your SVG */
  height: 100px;
  border-radius: 50%; /* Creates a round shape */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* You can also add additional styling for the SVG itself if needed */
.icon-container svg {
  width: 70%; /* Adjust the size of the SVG as needed */
  height: 70%;
}
.mainpart{
  width: 100%;
  overflow-x: auto;
}
/* CSS for the secondartpart class */
.secondartpart {
  padding: 1.25rem; /* Equivalent to p-5 */
  background-color: #fff; /* Equivalent to bg-white-100 */
  margin-top: 2.5rem; /* Equivalent to mt-10 */
  margin-bottom: 0; /* Equivalent to mb-0 */
  margin-left: 1rem; /* Equivalent to mx-4 */
  width: 90%; /* Equivalent to w-90 */
  justify-content: center; /* Equivalent to justify-center */
  border: 1px solid #374151; /* Equivalent to border dark:border-slate-700 */
  width: 1360px; /* Provided width in style attribute */
}
.prespart1 {
  display: flex;
  flex-direction: column;
}
.hr-line{border-top-width: 4px;border-color: #005d8d; margin-bottom: 20px }

.medicinebox{
  border: 1px solid #c1c5ca;
  /* border-bottom: 1px solid #1a202c; */
  padding: 0.5rem;
  text-align: left;
}
/* .p-dropdown-panel{

     min-width: 260px; 
    width: 25% !important;
    transform-origin: center top;
    top: 953.389px;

    left: 1300px !important;

} */
.carddiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Optionally set a height to center vertically */
  text-align: center;
}

.cardadmin {
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  padding: 20px;
  display: flex;
  flex-direction: column; /* Adjust if necessary based on your design */
}

.div1 {
  border: 1px solid gray;
    width: 31%;
    background-color: white;

}
.custom-input {
  /* background-color: gray; */
  border: 1px solid #6c757d; 
  border-radius: 132px; 
  height: 40px; 
  padding: 0 71px; 
  outline: none;
  margin: 0px 0px 0px 66px;

}

.custom-input:focus {
  border-color: #007BFF; /* Change the border color on focus */
  background-color: #f0f0f0; /* Change the background color on focus */
}
/* 


.p-inputwrapper{
  border-radius: 100px !important;
  border: 1px solid #6c757d;
  height: 40px;
  margin: 0px 0px 0px 114px;
  padding: 0 40px;
} */


