/* YourComponent.css */

.patientform-container {
    max-width: 1200px; /* Adjusted max-width for three columns */
    margin: 0 auto;
    padding: 20px;
    background-color: #f7faff; /* Light background color */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .patientform-row {
    display: flex;
    flex-wrap: wrap; 
    margin-top: 30px;
   
  }
  
  .patientform-group {
    flex: 1 1 33%; 
    padding: 10px; /* Adjust padding for spacing between columns */
    box-sizing: border-box;
  }
  
  .patientform-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .patientform-group input,
  .patientform-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da; /* Light grey border */
    border-radius: 5px;
    font-size: 16px;
  }
  
  .patientform-group input:focus,
  .patientform-group select:focus {
    outline: none;
    border-color: #007bff; /* Blue border on focus */
  }
  
  .patientform-error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .patientform-text-danger {
    color: red;
  }
  
  .patientform-group input[disabled] {
    background-color: #e9ecef;
  }
  
  @media (max-width: 768px) {
    .patientform-group {
      flex: 1 1 100%; /* Full width on small screens */
    }
  }
  /* YourComponent.css */

/* Button Container Styles */
.form-action-container {
    display: flex;
    width: 100%;
    margin: 20px 0; /* Equivalent to my-5 in Tailwind */
  }
  
  /* Button Styles */
  .form-action-button {
    padding: 15px; /* Equivalent to p-3 in Tailwind */
    background-color: #004172; /* Replace with your primary color */
    color: white; /* Equivalent to text-white in Tailwind */
    width: 100%; /* Equivalent to w-full in Tailwind */
    border-radius: 8px; /* Equivalent to rounded-lg in Tailwind */
    text-align: center;
    font-size: 16px; /* Equivalent to text-base in Tailwind */
    font-weight: 400; /* Equivalent to font-normal in Tailwind */
    cursor: pointer;
    border: none; /* Remove default border */
    transition: background-color 0.3s; /* Smooth background color transition */
  }
  
  /* Optional: Button hover state */
  .form-action-button:hover {
    background-color: #0056b3; /* Darker shade for hover */
  }
  
  /* Loader Styles (Optional) */
  .loader {
    border: 2px solid rgba(0, 0, 0, 0.1); /* Light grey border */
    border-top: 2px solid #000; /* Dark grey border-top */
    border-radius: 50%; /* Rounded shape */
    width: 20px; /* Equivalent to h-5 w-5 in Tailwind */
    height: 20px;
    animation: spin 1s linear infinite; /* Animation for spinning */
  }
  
  /* Keyframes for loader spin animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  