.searchbarsos {
    background-color: white;
    width: 239px;
    border-radius: 10px;
    height: 2.5rem;
    padding: 0 8px;
    box-shadow: 0px 0px 8 px #ddd;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
}

.placeholder {
    font-size: 16px;
}

.heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #262626;
}

.table-container {
    height: 124px;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    background-color: #FFFFFF;
}

.my-table td {
    padding: 11px;
    font-size: 14px;
    /* white-space: nowrap; */
}

.my-table th {
    font-size: 16px;
    padding: 8px;
    background-color: rgba(60, 186, 132, 0.08);
    white-space: nowrap;
}
.tableheading {
    /* border-top: 1px solid #ddd; */
    padding: "8px";
    color: #005D8E;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    padding-top: 15px;
    padding-bottom: 15px;
  
  }
  .inputpatientname {
    width: 100%;
/* height: 50px; */
   
padding: 10px 10px 10px 0px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 0px !important;
    /* margin: 7px; */
  
    background: #FFFFFF;
    border-bottom: 1px solid #E2E2E2;
    /* border-radius: 5px; */
  }
  .hello{
    width: 334px;
    height: 50px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      
        margin: 7px;
      
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 5px;
  }
  .p-dropdown-label{
    font-weight: 400;
    font-size: 14px;
  }
  

.p-dropdown-items{
    font-weight: 400;
    font-size: 14px;
  }
  .pi{
    font-weight: 400;
    font-size: 14px;
  }
  .p-inputtext{
    font-weight: 400;
    font-size: 14px;
  }
  .my-calendar {
    width: 300px;
    height: 300px;
    /* Other custom styles... */
  }
  .addbtn{
    background: #42BA85;
    color: #fff;
    border: 1px solid #42BA85;
    padding: 0px 23px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
    height: 37px;
    margin-top: 1px;
    cursor: pointer;
  }
  .add_btn{
    background: #42BA85;
    color: #fff;
    border: 1px solid #42BA85;
    padding: 0px 50px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
    height: 37px;
    margin-top: 1px;
    margin-top: 30px;
    box-shadow: 2px 2px 2px gray;
    cursor: pointer;
    margin-right: 170px;
  }
  .close_btn{
    background: #42BA85;
    color: #fff;
    border: 1px solid #42BA85;
    /* padding: 0px 50px; */
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
    height: 40px;
    margin-top: 1px;
    margin-top: 30px;
    box-shadow: 2px 2px 2px gray;
    cursor: pointer;
    width: 116px;
    float: right;
    margin-right: 204px;

  }

  .btndiv{text-align: center;}
  .inputpatientname_dropdown{
    width:100%;
    height: 33px;
       
      
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      
        margin: 7px;
      
        background: #FFFFFF;
        border-bottom: 1px solid #E2E2E2;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0px !important;
  }
  .inputpatientname_textarea{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    margin: 7px;
  
    background: #FFFFFF;
    border-bottom: 1px solid #E2E2E2;
    /* border-radius: 5px; */
    /* height: 50px; */
    /* padding: 10px 15px; */
    border-top: none;
    border-left: none;
    border-right: none;


  }
  /* .p-dialog-header{
    background: aliceblue!important;
    
  } */
  .p-dialog-content{
    padding-top: 18px !important;
  }
  .labelcss{
    font-size: 14px;
    font-weight: 500;
  }
  .edit{
    color: #005D8E;
    /* padding-left: 19px; */
    text-align: left !important;
    cursor: pointer;
    /* padding-top: 6px; */
    display: flex;
    /* justify-content: end; */
    /* margin-top: -21px; */
    /* margin-left: 16px; */
    text-align: center;
    margin-left: 9px;
  }
  .cont1{border: none;margin-top: -11px;}
  .cont2{border: 1px solid gray;margin-top: -11px;}
  .fontsomenew {
    color: #005D8E;
    padding-left: 9px;
    cursor: pointer;
    padding-top: 6px;
    display: flex;
    margin-top: -21px;
}
.edit{display: block;}
.editicon{display: none;}
.p-button.p-button-icon-only {
  padding: 6px 0;
}
.plush{
  font-size: 18px;
  color: green;
}
.dropdowntdsos{
 
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background: #FFFFFF;
  /* border: 1px solid #E2E2E2; */
  border-radius: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
}