@import "primeicons/primeicons.css";

@import "primereact/resources/themes/lara-light-indigo/theme.css";

@import "primereact/resources/primereact.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans';
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  /* body {
    overflow: hidden;
  } */

  .loader {
    border-top-color: #3498db !important;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}
.headerpart{
  height: 50px;
  position: fixed;
  z-index: 3;
  margin-top: -33px;
  width: -webkit-fill-available;
  width:  -moz-available;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  