.border-container {
    box-sizing: border-box;


    height: 54px;
    width: 100%;
   
    justify-content: space-between;
   
    align-items: center;
    padding: 10px;
    
    background: #FFFFFF;
    border: 0.5px solid rgba(173, 173, 173, 0.41);
    border-radius: 5px;
    z-index: 9;
}.borderInput{
    box-sizing: border-box;

width: 321px;
height: 49px;
left: 573px;
top: 384px;

border: 0.5px solid #004172;
border-radius: 5px;

}

.borderNewInput{
   

  
    border:none;



}

input:focus {
    outline:none;
}

.placeholder{


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
outline:none;/* identical to box height */

letter-spacing: 0.1px;
border:none;

color: #9D9FA2;
}.title{
  

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
margin-top: 15px;
/* identical to box height */

letter-spacing: 0.1px;

color: #ADADAD;
}