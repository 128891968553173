.container-main {
    width: 100%;
    height: 200pt;
  display: flex;
  justify-content: center;
  justify-self: center;
  padding: 20px;
  align-items: center;
  z-index: 1;

 
}
