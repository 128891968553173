.maindiv{
    display: flex;
    margin-top: -85px;
}
.scrn1{
    width: 200px;
}
.scrn2{
    width: 110px;
}
.sidebar{
    height: 100vh;
    padding:20px;
    position: relative;
    transition-duration: 300ms;
}
.scrn2 .list{
    text-align: center;
}
.nevBlock-active {
    /* background: rgba(60, 186, 132, 0.1); */
  
    /* font-weight: 600; */
    line-height: 21px;
    letter-spacing: 0.1px;
    /* color: #00654d; */
    color: #004171;
  }
  .nevBlock {

    cursor: pointer;
    display: block;
    text-decoration: none;
  
    margin-left: 38px !important;
    font-weight: 400;
   
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1px;
   
    color: #9D9FA2 ;
    display: flex;
  }
.img1{
    position: absolute;
    cursor: pointer;
    top: 48px;
    right: -21px;
    transform: translateY(-50%);
    height:30px;
    width: 30px;
   background-color: rgba(0, 178, 221, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
   color:black;
   font-size: 16px;
}
.imgr{
    transform: rotate(180deg);
    top:33px;
    right:-22px;

}
.div2{
    display: flex;
    column-gap: 1rem;
    margin-top: 85px;
    /* margin-top: 20px; */
    /* align-items: center; */
}
.logo{
    cursor: pointer;
    transition-duration: 500ms;
    /* margin-right: 9px; */
}
.logoname{
    width: 150px;
    padding-left: 6px;
}
.scrn2 .logo{
    margin-left: 10px;
}
.logo1{
    --tw-rotate: 360deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.menu{
    padding-top: 1.5rem;
    /* margin-right: 10px; */
    padding-left: 0px !important;
    /* text-align: center; */
}
.scrn2 .icons{
    margin-left: 18px;
}

.list{
    display: block;
    /* border-radius: 0.375rem; */
    /* padding: 8px; */
    cursor: pointer;
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
    font-size:  16px ;
    line-height: 1.25rem/* 20px */;
   
    column-gap: 1rem;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #CDCDCD;
    padding-top: 15px;
}

.top1{
    margin-top: 2.25rem;
}
.top2{
    margin-top: 0.5rem;
    display: flex;
}
.bgclr{
    background-color: white;
}
.disp{
    display: none;
}
.disp2{
    transform-origin: left;
    transition-duration: 200ms;
    color: black;
}
.dropdown{
    float: right;
    margin-top: 4px;
    color: black;

}
.submenu{
    display: block;
    font-size: 14px;
    color: black;
    margin-top: 10px;
    margin-left: -5px;
}
.icons{
    margin-bottom: -9px;
    padding-right: 10px;
}

  .tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; */
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 15;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

 