.container-loader {
    border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
 
}.container-main {
    width: 100%;
  display: flex;
  justify-content: center;
  justify-self: center;
  padding: 20px;

  
  
  z-index: 1;

 
}
