.container {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  background: #fdfdfd;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
}

.form-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.form-label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

.input-group {
  display: flex;
  gap: 15px;
  align-items: center;
}

.input-group input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.suggestions-dropdown {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
}

.suggestion-item {
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.cart-section {
  margin-top: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
}

.cart-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.button-primary,
.button-success,
.button-danger {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-primary {
  background: linear-gradient(45deg, #42a5f5, #1e88e5);
}

.button-success {
  background: linear-gradient(45deg, #66bb6a, #43a047);
}

.button-danger {
  background: linear-gradient(45deg, #ef5350, #e53935);
}

.button-primary:hover {
  background: linear-gradient(45deg, #1e88e5, #1565c0);
}

.button-success:hover {
  background: linear-gradient(45deg, #43a047, #2e7d32);
}

.button-danger:hover {
  background: linear-gradient(45deg, #e53935, #b71c1c);
}

.cart-summary {
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}

.cart-summary h4 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  color: #444;
}

.cart-summary p {
  font-size: 16px;
  margin-bottom: 10px;
}

.address-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}

.address-type {
  font-size: 0.85rem;
  font-weight: 600;
  color: #42ba85;
  background: #e6f7e6;
  padding: 5px 10px;
  border-radius: 15px;
}



/* Date Selection */
.date-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.date-selection button {
  border: none;
  background-color: #f0f0f0;
  padding: 8px 12px;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.date-selection button:hover {
  background-color: #42ba85;
  color: #fff;
}

.date-btn {
  background-color: #fff;
  border: 2px solid #42ba85;
  color: #42ba85;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  width: 100px;
}

.date-btn.active {
  background-color: #42ba85;
  color: white;
}

.date-btn:hover {
  background-color: #42ba85;
  color: white;
}

/* Time Slot Styling */
.time-slot-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  gap: 8px;
}

.time-slot {
  border: 2px solid #42ba85;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  background-color: white;
  color: #42ba85;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.time-slot.active {
  background-color: #42ba85;
  color: white;
}

.time-slot:hover {
  background-color: #42ba85;
  color: white;
}

/* Selected Information */
.selected-info {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-top: 15px;
}
.time-slot.disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}
