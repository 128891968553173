.border-container {
    box-sizing: border-box;


    height: 54px;
    width: 100%;
   
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px;
    
    background: #FFFFFF;
    border: 0.5px solid rgba(173, 173, 173, 0.41);
    border-radius: 5px;
    position: relative;
    z-index: 0;
   
}.placeholder{


font-family: 'Open Sans';
font-style: normal;
width: 100%;


font-weight: 400;
font-size: 18px;
line-height: 25px;
outline:none;/* identical to box height */

letter-spacing: 0.1px;

border:none;



color: #9D9FA2;
}.placeholderDate{


    font-family: 'Open Sans';
    font-style: normal;
    width: 95%;
    
    
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    outline:none;/* identical to box height */
    
    letter-spacing: 0.1px;
    

    border:none;
    
    
    
    color: #9D9FA2;
    }

.dateView{
    width: 100%;
    
}