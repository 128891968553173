.profilepage-breadcrumb {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #3a3a3a;

 
}.flagView{
  flex-direction: row;
  align-items: center;
  display: flex;

}
.titleBusinessEvent{
  

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;

/* identical to box height */

letter-spacing: 0.1px;

color: #000000;
}.dateContainer{
  display: flex;
  justify-content: space-between;
}.dateContainerAlert{
  display: flex;
  justify-content: space-between;
}

.hospitalEnquiry{
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;
/* identical to box height */

letter-spacing: 0.1px;

color: #000000;

}.hospitalEnquiryBody{
  display: flex;
  justify-content: space-between;
}.response{


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

letter-spacing: 0.1px;

color: #3CBA84;
}.selectDate{
 

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

letter-spacing: 0.1px;

color: #3A3A3A;
}.selectedDateContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}.calenderImg{
  width: 18px;
  height: 18px;
  margin-right: 10px;
}.listContainer{
  box-sizing: border-box;
background: #FFFFFF;
border: 0.5px solid rgba(157, 159, 162, 0.42);
border-radius: 5px;
}

.listBorder{


background: rgba(60, 186, 132, 0.05);
border-radius: 5px 5px 0px 0px;
display: flex;
justify-content: space-around;

}.listTitle{

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 30px;
word-break: keep-all; 
  word-wrap: break-word;
  width: 125px;
  text-align: center;


/* identical to box height */

letter-spacing: 0.1px;

color: #005D8E;
}.rowListEvent{
  display: flex;
 
  background: rgba(157, 159, 162, 0.42);
  margin-bottom:0.5px;
 
}.boxSub{
 padding: 10px;


 flex: 1;
 text-overflow: ellipsis;
  
 background-color: #ffffff;
 overflow: hidden;

 
  
}
.rowList{
  display: flex;

  background: rgba(157, 159, 162, 0.42);
  
  gap: 0.5px;
 
}
.infinitiveScroll{

  width: 100%;
  height: 100%;
  

}

.boxMain{
background: rgba(157, 159, 162, 0.42);
  
}.addComment{
  box-sizing: border-box;
  margin-top: 2px;



background: #FFFFFF;
border: 0.5px solid #ADADAD;
border-radius: 5px;

}.inputComment{
  outline:none;/* identical to box height */

letter-spacing: 0.1px;
word-break: break-all;
border:none;
}.listNewContainer{
  margin-bottom: 20%;
  overflow: auto;
  flex-wrap: initial;
}.serviceName{

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 30px;
word-break: keep-all;  
word-wrap: break-word;

/* identical to box height */
text-align: center;

letter-spacing: 0.1px;

color: #3A3A3A;
}.patientIdNew{


  font-family: 'Open Sans';
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
   
  word-break: keep-all;  
   word-wrap: break-word;
   align-self: center;
   text-align: center;
   text-decoration: underline;
  cursor:pointer ;
   
  
  
  letter-spacing: 0.1px;
  
  color: rgba(58, 58, 58, 0.76);
  }.patientComment{


    font-family: 'Open Sans';
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
     
    word-break: keep-all;  
     word-wrap: break-word;
     align-self: center;
     text-align: center;
     text-decoration: underline;
    cursor:pointer ;
     
    
    
    letter-spacing: 0.1px;
    
    color: rgba(7, 7, 239, 0.76);
    }

.patientId{


font-family: 'Open Sans';
width: 100%;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
 
word-break: keep-all;  
 word-wrap: break-word;
 align-self: center;
 text-align: center;



letter-spacing: 0.1px;

color: rgba(58, 58, 58, 0.76);
}.transactionDate{
  

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

text-align: center;
letter-spacing: 0.1px;

color: #3A3A3A;

}.typeComment{


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

letter-spacing: 0.1px;
margin-top: 5px;
margin-left: 5px;

color: #9D9FA2;
outline:none;/* identical to box height */

letter-spacing: 0.1px;
border:none;

}.refundMoney{
 

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

text-align: center;
letter-spacing: 0.1px;
text-decoration-line: underline;

color: #3CBA84;
}.divRefund{
  display: grid;
  justify-content: space-between;

}.topping{
  margin-top: 10px;
  padding: 5px;
  
}.calenderFlag{
  margin-left: 8px;
  margin-top: 5px;
  align-self: center;

  width: 18px;
  height: 18px;
}

