.headingevent{
       
        padding: "8px";
        color: #005D8E;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        padding-top: 11px;
        padding-bottom: 11px;
}
.activenew{margin-left: 248px;}
.inactivenew{margin-left: 0px;}