.headercss{
    height: 57px;
    background-color: rgb(244 250 255);
    border-radius: 7px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 2px 5px #ccc;
    margin-top: 40px;
    display: flex;
    /* justify-content: space-evenly; */
}
.subheaderCss{
    font-size: 21px;
    font-weight: 700;
    padding: 15px 22px;
    color: #1b254b;
}
.loginflowcss{
    width: 50%;
}
.icon-button {
        position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 49px;
      height: 46px;
      color: #333333;
      background: rgb(181, 223, 246, 0.23);
      border: rgb(181, 223, 246, 0.23);
      outline: none;
      border-radius: 50%;
  
  }
  
  .icon-button:hover {
    cursor: pointer;
  }
  
  .icon-button:active {
    background: #cccccc;
  }
  
  .icon-button__badge {
    position: absolute;
    top: -1px;
    right: -10px;
    width: 21px;
    height: 21px;
    background: green;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 800;
}
  .custom-overlay{
    width: 500px;
    height:300px;
    font-size: 12px;
    overflow-y: scroll ;
  }
  .bellcss{
    font-size: 20px !important;
  }


