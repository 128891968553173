.profilepage-breadcrumb {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #3a3a3a;

  margin-bottom: 30px;
}.leftBox{

width: 261px;
height: 500px;
display: grid;
background: #FFFFFF;
border: 0.5px solid rgba(157, 159, 162, 0.42);
border-radius: 5px;
padding: 10px;
}.rightBox{

width: 830px;
height: 450px;


background: #FFFFFF;
border: 0.5px solid rgba(157, 159, 162, 0.42);
border-radius: 5px;
padding: 20px;
}.subMainContainer{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}.userImage{
  width: 120px;
height: 120px;
border-radius: 60px;
justify-self: center;
background: aqua;

}.aboutHim{
  
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
text-align: center;
letter-spacing: 0.1px;

color: #3A3A3A;
}.aboutTitle{

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 30px;
text-align: center;
/* identical to box height */

letter-spacing: 0.1px;

color: #005D8E;

}.nameTitle{

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 22px;
margin-top: 10px;

text-align: center;
/* identical to box height */



color: #000000;
}.profileContainer{
  
  justify-self:center;
  margin-top: 20px;
}

.service{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
text-align: center;
/* identical to box height */

letter-spacing: 0.1px;

color: #ADADAD;
}.editProfile{

  box-sizing: border-box;


width: 90px;
height: 39px;
display: flex;
justify-content: center;
align-items: center;



border: 0.5px solid #004171;
border-radius: 6px
}.myprofilebtnContainer{
  display: flex;
  justify-content: space-between;

}.divBorder{
  width: 830px;
height: 1px;


background: rgba(0, 0, 0, 0.1);
transform: matrix(1, 0, 0, -1, 0, 0);
}.namePhoneContainer{
  display: flex;
  justify-content: space-between;
}.inputMainContainer{
  display: flex;
  justify-content: space-between;


 
}.subContainerAll{
  margin-right: 20px;
  display: grid;
  margin-top: 20px;
  width: 90%;


  flex: 1;
  height: 90%;

}.titleBlack{

top: 286px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
/* identical to box height */

letter-spacing: 0.1px;

color: #3A3A3A;
}.myProfile{


font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 30px;
/* identical to box height */

letter-spacing: 0.1px;

color: #000000;
}.editTxt{
 

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 20px;
/* identical to box height, or 111% */

display: flex;
align-items: center;
text-align: center;

color: #004171;

}