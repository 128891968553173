
.dialogContainer {
   
    justify-content: center;
    align-items: center;
   
    height:'400px';
    scroll-behavior: auto;
    overflow-y: scroll;
    max-height: 450px;
  
  }.main{
    flex: 1;

  }
  .mainTestCode{
    display: flex;
    justify-content: space-between;


  }
  .subContainer{
    
    display: flex;
    width: 100%;
    justify-content:'space-between';
    margin-bottom: 25px;
    margin-top: 10px;
  }.titleOfDialog{
    font-family: 'Open Sans';
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

     align-self: center;
     text-align: center;    
    letter-spacing: 0.1px;
    
    color: rgba(58, 58, 58, 0.76);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;

    }.titleMain{


        font-family: 'Open Sans';
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
    
         align-self: center;
         text-align: center;
        
        
        
        letter-spacing: 0.1px;
        
        color: rgb(15, 12, 12);
        }.medicineList{
            flex: 1;
        }.titleOfNew{
            font-family: 'Open Sans';
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 25px;
        
             align-self: center;
             text-align: center;    
            letter-spacing: 0.1px;
            
            color: rgb(15, 12, 12);
            }.btmContainer{
              display: flex;
              justify-content: space-around;
            }
            .primaryConBtn {

              box-sizing: border-box;
              width: 40%;
              height: 45px;
              background: #004171;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
            
            
            
            }.cancelConBtn {
              width: 40%;
              height: 45px;
            
              border: 0.5px solid #004171;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
            
            
            }.btnCon {
              display: flex;
              justify-content: space-around;
              width: 100%;
              margin-top: 20px;
            }
  