.dropdownTopContainer {
  z-index: 2;
  position: relative;
 
}

.dropdownContainerNew {
  padding-left: 10px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  margin-left: 5px;

  align-items: center;
  cursor: pointer;

  box-sizing: border-box;

  width: 100%;
  height: 30px;
 
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 5px;
  
  background: #FFFFFF;
  border: 0.5px solid rgba(173, 173, 173, 0.41);
  border-radius: 5px;
  margin-top: 10px;
  
}

.dropdownOptionsContainer {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  padding-left: 15px;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  box-shadow: 1px 22px 40px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

.dropDownListContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #9d9fa2;
  margin-bottom: 16px;
  cursor: pointer;
}

.dropDownListContainer > img {
  height: 18px;
  width: 18px;
  margin-right: 14px;
  margin-top: 2px;
}

.dropdownDownArrow {
  height: 8px;
  width: 12px;
}

.dropdownText {
  flex: 9;
  font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
outline:none;/* identical to box height */

letter-spacing: 0.1px;
border:none;

color: #9D9FA2;
  color: #9D9FA2;
  
}

.dropdownArrowContainer {
  padding-left: 10px;
  margin-right: 10px;
 
}

.overlayForDropdown {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}
