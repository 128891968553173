.frststpart{
  display: flex;
  background-color: white;
  height: 100px;
  margin-top: 50px;

}
.text{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
/* line-height: 49px; */
color: #004172;
/* margin-left: 50px; */
}
.imgdiv{
    /* margin-left: 200px; */
   
    position: relative;
}
.img{
    position: absolute;
    /* left: 200px; */
    bottom: 0px;
}
.secpart{
    /* display: flex; */
  
    /* width: 570px;
height: 246px; */
margin-top: 30px;
}
.partdiv{
    display: block;
    gap: 10px;
    
}
.texthead{
    margin-left: 27px;
}
.partdiv1{
    display: flex;
   
   gap: 20px;
    justify-content: space-around;
    margin-left: 25px;
}
.part1{
    display: flex;
     width: 350px;
    /* height: 152px; */
background: rgba(60, 186, 132, 0.06);
border-radius: 4px;
padding: 10px;
/* gap: 50px; */
font-size: 20px;
}
.part2{
    display: flex;
      width: 350px;
    /* height: 152px; */
background: rgba(0, 178, 221, 0.06);
border-radius: 4px;
padding: 10px;
font-size: 20px;
gap: 50px;
}
.part3{
    display: flex;
    width: 350px;
    /* height: 152px; */
background: rgba(0, 93, 142, 0.06);
border-radius: 4px;
padding: 10px;
font-size: 20px;
gap: 50px;
}
.btn-4 {
  background-color: #4dccc6;
background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
  line-height: 42px;
  padding: 0;
  border: none;
  width: 283px;
    height: 100px;
    border-radius: 7px;
    text-align: center;
}
.btn-4:hover{
  background-color: #89d8d3;
background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}
.btn-4 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-4:before,
.btn-4:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
   box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  transition: all 0.3s ease;
}
.btn-4:before {
  height: 0%;
  width: .1px;
}
.btn-4:after {
  width: 0%;
  height: .1px;
}
.btn-4:hover:before {
  height: 100%;
}
.btn-4:hover:after {
  width: 100%;
}
.btn-4 span:before,
.btn-4 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  transition: all 0.3s ease;
}
.btn-4 span:before {
  width: .1px;
  height: 0%;
}
.btn-4 span:after {
  width: 0%;
  height: .1px;
}
.btn-4 span:hover:before {
  height: 100%;
}
.btn-4 span:hover:after {
  width: 100%;
}
.catogorydiv{
  display: flex;
  /* justify-content: space-between; */
  margin-top: 36px;
  padding: 18px;
  padding-left: 97px;
  padding-right: 65px;
  gap: 115px;
}
.textdash{
  margin-top: 27px;
    font-size: 20px;
    font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 10px 10px;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #005D8E;
 background-color: white;
 /* font-size: 1px; */
  transition: box-shadow 0.2s;
  /* border-radius: 5px; */
}
.boxcss{
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    position: relative;
    border-radius: 20px;
    min-width: 0px;
    word-wrap: break-word;
    background-color: #ffffff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 0px;

}
.p-datatable .p-paginator-bottom {
  border-width: 0px;
 border-radius: 0px;
  padding: 0px;
}
.dropdowndashcss{
  border-top: none;
  border-left: none;
  border-right: none;
  /* margin-left: 25px; */
}
.btn-2 {
  background: #005D8E;
  background: linear-gradient(0deg, #005D8E 0%, 
  #005D8E 100%);
  border: none;
  
}
.btn-2:before {
  height: 0%;
  width: 2px;
}
.btn-2:hover {
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .5), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
}
.custom-btn {
  /* width: 130px; */
  /* height: 40px; */
  color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 500;
  /* background: transparent; */
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}
.containerdash {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 53px;
  padding: 20px;
}

.boxdash {
  display: flex;
  gap: 20px;
  height: 110px;
  padding: 0px 15px;

  /* display: block; */
  margin-left: 10px;

 
  font-weight: 500;

  cursor: pointer;
  border-radius: 7px;
 
  background-color: #ffffff;
 
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  width:98%;

}
.month{
  padding: 13px;
 
  padding-top: 23px;
}

.imagedivdash{
  /* width: 40%; */
margin-top:18px;
  font-size: 28px;
  color: cornflowerblue;
  text-align: center;
}
.textdash{
  font-size: 16px;
  font-weight: 700;
  color: #616161;
  margin-bottom: 0px;
  margin-top: 20px;
}
.numberdash{
  margin-top: 10px;
  font-size: 20px;
  color: green;
  font-weight: 700;
  /* margin-left: 35px; */
}
.datePickerDash{
  margin-top: 20px;
 

}
.formcontroldash{
  border-left: none;
  border-right: none;

  border-top: none;
  padding-bottom: 6px;
  border-color: #828282;
}






  