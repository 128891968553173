.appHeader-container {
  position: relative;
  /* background-color: rgb(181, 223, 246); */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); */
  height: 55px;
  align-items: center;
  padding: 10px;
  height: 71px;
  /* z-index: 10; */

  display: flex;
}
.date-time{
  display: grid;
  margin-left: 259px;
  grid-gap: 9px;
}
.date-time-day{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 26px;
}
.userDropDown-iconContainer {
  margin-left: auto;

  /* margin-right: 15px; */

  position: relative;
  z-index: 3;
}
.userDropDown-icon:hover {
  background: rgb(181, 223, 246, 0.23);
}

.userDropDown-icon {
  background: rgb(181, 223, 246, 0.23);
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
/* .userDropDown-iconContainer:hover .userDropDown-container {
  display: block;
} */
.userDropDown-container {
  /* display: none; */

  position: absolute;
  top: 63px;
  right: 0;

  min-width: 200px;
  width: max-content;
  padding: 15px;

  border-radius: 10px;
  background: #255b89;
  border: 1px solid #255b89;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: center;
  z-index: 3;
}
.userDropDown-container:after {
  content: " ";
  position: absolute;
  right: 8px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 35px solid #255b89;
  border-radius: 15px;
}

.userDropDown-blockContainer {
  border-radius: 5px;
  background: #b5dff6;
  border: 1px solid #30446d;
  color: #255b89;
  margin: 15px 0;
  text-align: left;
}
.userDropDown-block {
  padding: 15px;
  border-bottom: 1px solid #30446d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #004171;
  cursor: pointer;
  text-decoration: none;
  display: flex;
}
.userDropDown-block:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.userDropDown-block.active {
  background-color: rgba(255, 255, 255, 0.3);
}
.userDropDown-block:last-child {
  border: none;
}
.userDropDown-logout {
  display: flex;
  padding: 15px;
  border: 1px solid #30446d;
  border-radius: 5px;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: left;
  letter-spacing: 0.1px;

  color: #b5dff6;
  cursor: pointer;
}
.userDropDown-logout:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
